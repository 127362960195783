
import { defineComponent, onMounted, watch, ref } from "vue";
import { useRoute } from "vue-router";
import ShowcasesCard from "@/views/studio-lab/components/cards/ShowcasesCard.vue";
import ActivityService from "@/core/services/ActivityService";
import { IActivity } from "@/core/data/activity";
import UserService from "@/core/services/UserService";
import { IActivityRoom } from "@/core/data/activityRoom";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import UserRoleService from "@/core/services/UserRoleService";

export default defineComponent({
  name: "view-all-showcases",
  components: { ShowcasesCard },
  setup(props, { emit }) {
    const route = useRoute();
    const isTeacher = ref<boolean>(false);
    const currentActivity = ref<IActivity>();
    const currentActivityRoom = ref<IActivityRoom | null>();
    const posts = ref<Array<IActivityRoomPost>>([]);

    const initActivity = () => {
      ActivityService.getActivityById(route.params.id).then((response) => {
        currentActivity.value = response.data;
      });
    };

    const getYourActivityRoom = () => {
      UserService.getMyActivityRoom(route.params.id).then((response) => {
        currentActivityRoom.value = response.data;
        initPosts();
      });
    };

    const initPosts = () => {
      if (
        currentActivityRoom.value != null &&
        currentActivityRoom.value != undefined
      ) {
        ActivityRoomPostService.getPostByActivityRoom(
          currentActivityRoom.value?.id
        ).then((response) => {
          posts.value = response.data;
        });
      }
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    const onRoomChange = () => {
      initPosts();
    };

    watch(
      () => route.params.id,
      (state, prevState) => {
        initActivity();
        currentActivityRoom.value = null;
        posts.value.splice(0, posts.value.length);
      }
    );

    onMounted(() => {
      initActivity();
      getTeacher();
      getYourActivityRoom();
    });

    return {
      posts,
      currentActivity,
      currentActivityRoom,
      initPosts,
      isTeacher,
      onRoomChange,
    };
  },
});
