import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container p-0" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start mb-5" }
const _hoisted_3 = { class: "fs-5 text-muted" }
const _hoisted_4 = { class: "d-flex justify-content-end align-items-center mb-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "row row-cols-1 row-cols-sm-2" }
const _hoisted_7 = {
  key: 1,
  class: "d-flex flex-center my-10"
}
const _hoisted_8 = {
  key: 0,
  class: "text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_showcases_card = _resolveComponent("showcases-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'Activity Detail', params: { id: _ctx.currentActivity?.id } },
        target: "_blank",
        class: "fs-2 fw-bolder"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.currentActivity?.title), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_router_link, {
        to: {
          name: 'Topic Detail',
          params: { id: _ctx.currentActivity?.topic?.id },
        },
        target: "_blank"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.currentActivity?.topic?.title), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isTeacher)
        ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            prop: "caseStatus",
            class: "mb-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                ref: "caseStatusSelectRef",
                modelValue: _ctx.currentActivityRoom,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentActivityRoom) = $event)),
                "value-key": "id",
                filterable: "",
                placeholder: "Select room",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onRoomChange()))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentActivity?.activity_rooms, (activityRoom) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: activityRoom.id,
                      label: activityRoom.room?.name,
                      value: activityRoom
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(activityRoom?.room?.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.posts.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post, p) => {
              return (_openBlock(), _createElementBlock("div", {
                key: p,
                class: "col"
              }, [
                _createVNode(_component_showcases_card, {
                  currentPost: post,
                  onOnRefreshPost: _ctx.initPosts,
                  isTeacher: _ctx.isTeacher
                }, null, 8, ["currentPost", "onOnRefreshPost", "isTeacher"])
              ]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.currentActivityRoom)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_8, " There's no any showcase "))
            : _createCommentVNode("", true)
        ]))
  ]))
}