
import { IActivityPostLike } from "@/core/data/activityPostLike";
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import { IActivityRoomComment } from "@/core/data/activityRoomComment";
import { IRoomGroup } from "@/core/data/roomGroups";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import ActivityRoomCommentService from "@/core/services/ActivityRoomCommentService";
import { useAuthStore } from "@/store/useAuthStore";
import ViewGroupMemberModal from "@/views/studio-lab/components/modals/ViewGroupMemberModal.vue";
// import CommentCard from "@/views/studio-lab/components/cards/CommentCard.vue";
import { PropType, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "showcases-card",
  components: { ViewGroupMemberModal },
  props: {
    currentPost: Object as PropType<IActivityRoomPost>,
    isTeacher: Boolean,
  },
  emit: ["onRefreshPost"],
  setup(props, { emit }) {
    const authStore = useAuthStore();
    const currentComment = ref("");
    const isLike = ref<boolean>(false);
    const activityPostLikes = ref<Array<IActivityPostLike>>([]);
    const viewGroupMemberRef = ref();
    const roomGroup = ref<IRoomGroup>();
    const activityAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activity-room-posts/attachments/"
    );

    const initLikes = () => {
      ActivityRoomPostService.getPostLikeByPostId(props.currentPost?.id).then(
        (response) => {
          activityPostLikes.value = response.data;

          let activityPostLike = activityPostLikes.value.find(
            (obj) => obj.user?.id === authStore.currentUser?.id
          );

          isLike.value = activityPostLike != undefined ? true : false;
        }
      );
    };

    const showGroupMembers = (group: IRoomGroup) => {
      roomGroup.value = group;
      viewGroupMemberRef.value.showModal();
    };

    const addComment = () => {
      if (currentComment.value != "" && currentComment.value != undefined) {
        let comment = ref<IActivityRoomComment>({
          id: null,
          post: props.currentPost!,
          user: authStore.currentUser!,
          comment: currentComment.value,
          created_at: new Date(),
        });

        ActivityRoomCommentService.createComment(comment.value)
          .then((response) => {
            currentComment.value = "";
            onRefreshPost();
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    };

    const likePost = () => {
      isLike.value = !isLike.value;

      if (isLike.value) {
        let postLike = ref<IActivityPostLike>({
          id: null,
          post: props.currentPost!,
          user: authStore.currentUser!,
        });
        ActivityRoomPostService.likePost(postLike.value).then((response) => {
          initLikes();
        });
      } else {
        ActivityRoomPostService.unlikePost(props.currentPost?.id).then(
          (response) => {
            initLikes();
          }
        );
      }
    };

    const onRefreshPost = () => {
      emit("onRefreshPost");
    };

    const acceptPost = () => {
      if (props.currentPost?.is_accept) {
        ActivityRoomPostService.unAcceptPost(props.currentPost?.id).then(
          (response) => {
            onRefreshPost();
          }
        );
      } else {
        ActivityRoomPostService.acceptPost(props.currentPost?.id).then(
          (response) => {
            onRefreshPost();
          }
        );
      }
    };

    onMounted(() => {
      initLikes();
      console.log(props.currentPost);
    });
    return {
      currentComment,
      activityPostLikes,
      isLike,
      likePost,
      acceptPost,
      viewGroupMemberRef,
      showGroupMembers,
      roomGroup,
      onRefreshPost,
      addComment,
      activityAttachmentbaseURL,
    };
  },
});
