import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-body p-4" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = { class: "d-flex align-items-start mb-5" }
const _hoisted_5 = { class: "symbol symbol-100px me-3" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "d-flex flex-column justify-centent-start overflow-hidden" }
const _hoisted_8 = { class: "d-flex align-items-center mb-2" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "symbol-label fs-2 fw-semibold bg-secondary text-inverse-danger"
}
const _hoisted_11 = { class: "card-text" }
const _hoisted_12 = { class: "card-footer p-4" }
const _hoisted_13 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_14 = { class: "d-flex flex-center fs-5 fw-bolder" }
const _hoisted_15 = { class: "fs-8 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ViewGroupMemberModal = _resolveComponent("ViewGroupMemberModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.currentPost?.attachments && _ctx.currentPost?.attachments.length > 0)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.activityAttachmentbaseURL + _ctx.currentPost?.attachments[0].id,
                  alt: ""
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_link, {
                to: { name: 'View Showcase', params: { id: _ctx.currentPost?.id } },
                class: "card-title fs-4 fw-bold my-0 me-5"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentPost?.group?.name), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              (_ctx.isTeacher)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "d-flex flex-center btn btn-sm p-2",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.acceptPost())),
                    type: "button"
                  }, [
                    _createElementVNode("i", {
                      class: _normalizeClass(["fs-4 p-0 bi", [
                    _ctx.currentPost?.is_accept
                      ? 'bi-star-fill text-warning'
                      : 'bi-star',
                  ]])
                    }, null, 2)
                  ]))
                : (_openBlock(), _createElementBlock("i", {
                    key: 1,
                    class: _normalizeClass(["fs-4 p-0 bi", [
                  _ctx.currentPost?.is_accept
                    ? 'bi-star-fill text-warning'
                    : 'bi-star',
                ]])
                  }, null, 2))
            ]),
            (_ctx.currentPost?.group)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "symbol-group symbol-hover mb-5",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showGroupMembers(_ctx.currentPost?.group)))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentPost?.group?.members, (member, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "symbol symbol-circle symbol-25px"
                    }, [
                      (index <= 5)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "Logo",
                            src: member.user.avatar,
                            referrerpolicy: "no-referrer"
                          }, null, 8, _hoisted_9))
                        : (_openBlock(), _createElementBlock("div", _hoisted_10, " + "))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.currentPost?.post), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, [
          _createElementVNode("button", {
            class: "btn btn-sm btn-bg-white hover-elevate-up text-center p-2",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.likePost())),
            type: "submit"
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi fs-3 me-2", [
                _ctx.isLike
                  ? 'bi-emoji-heart-eyes-fill text-danger'
                  : 'bi-emoji-heart-eyes',
              ]])
            }, null, 2)
          ]),
          _createTextVNode(" " + _toDisplayString(_ctx.activityPostLikes.length), 1)
        ]),
        _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.currentPost?.comments?.length) + " " + _toDisplayString(_ctx.currentPost?.comments?.length > 1 ? "comments" : "comment"), 1)
      ])
    ]),
    _createVNode(_component_ViewGroupMemberModal, {
      ref: "viewGroupMemberRef",
      group: _ctx.roomGroup
    }, null, 8, ["group"])
  ]))
}